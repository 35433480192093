app = angular.module 'hestieck', [], ($interpolateProvider) ->
	$interpolateProvider.startSymbol '<%'
	$interpolateProvider.endSymbol '%>'

app.controller 'TimesheetsController', ->
	if Hestieck.timesheet_bootstrap_data?
		@currentPos = 0
		@positions = Hestieck.timesheet_bootstrap_data[0].positions.map (position) =>
			@currentPos++
			position.pos = @currentPos
			return position
	else
		@currentPos = 1
		@positions = [{pos: 1, id: 0, shop_id: null, kunde: '', created_at: moment().format('YYYY-MM-DD'), modification_id: null, work_time: ''}]

	@addRow = ->
		@currentPos++
		@positions.push pos: @currentPos, created_at: moment().format('YYYY-MM-DD'), id: 0

	@removeRow = (row_nr) ->
		tempPositions = []
		if @positions.length != 1
			tempPositions.push item for item in @positions when item.pos isnt row_nr
			@positions = tempPositions
			@currentPos--

